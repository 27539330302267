
import CloseIcon from "@/components/system/CloseIcon.vue";
import {defineComponent, onMounted} from 'vue'
import EmptyNoLesson from "@/components/timetable/EmptyNoLesson.vue";
import {windTableBill} from "@/views/timetable/myTimetable/WindTable/WindTable";
import DwAvatar from "@/components/system/DwAvatar.vue";

export default defineComponent({
  name: "WindTable",
  components: {
    DwAvatar,
    EmptyNoLesson,
    CloseIcon,

  },
  data() {
    return {}
  },
  setup(){
    const {
      refData,
      init,
    } = windTableBill();

    onMounted(async ()=>{
      await init();
    })

    return {
      ...refData,
    }
  },
  mounted() {

  },
  methods: {

  },
})
